<template>
    <v-container class="pa-8">
    <div class="text-h5 font-weight-bold grey--text text--darken-2 mb-10">
        <v-icon>mdi-chart-box</v-icon>
        RAEME Module <span class="primary--text">({{coverageDate}})</span>

        <v-btn color="primary" class="ml-4 float-right text-body-1" @click="historyDrawer = !historyDrawer">
            <v-icon class="mr-1">mdi-history</v-icon>
            Report Coverage
        </v-btn>

        <v-select
            :items="returnDept"
            v-model="deptFilter"
            label="Select Department"
            class="float-right text-body-1"
            outlined
            v-show="returnORMPFilter"
            style="width: 250px;"
            dense
        ></v-select>

        <v-text-field
            label="Search"
            class="float-right text-body-1 mr-4"
            dense
            append-icon="mdi-magnify"
            outlined
            v-model="search"

        ></v-text-field>
    </div>
    <v-banner two-line class="mb-4" color="white" v-show="showBanner">
        <v-avatar
        slot="icon"
        color="primary"
        size="40"
        >
        <v-icon
            icon="mdi-info"
            color="white"
        >
            mdi-alert
        </v-icon>
        </v-avatar>

        There is no RAEME Report for this query. <br> 
        <span class="primary--text font-weight-bold">{{query}}</span>

        <template v-slot:actions>
        <v-btn
            text
            color="primary"
            @click="$router.go(-1)"
        >
            RETURN TO DASHBOARD
        </v-btn>

        </template>
    </v-banner>    
    <v-card elevation="0">
        <v-data-table
            :headers="headers"
            :items="returnORMP"
            :items-per-page="5"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            show-expand
            :update:expanded.sync="updated"
            item-key="Rank"
            :search="search"
        >
            <template v-slot:expanded-item="{ item, headers }">
                <td :colspan="headers.length">
                    
                    <v-row class=" white--text grey darken-3 mt-4">
                        <div class="col-3 ml-2">Treatments</div>
                        <div class="col ml-2">Reports</div>
                    </v-row>
                    <v-row class="full-width " v-for="(Action,idx) in item.Reports" :key="idx + $lodash.uniqueId()">
                    <div class="col-3 text-title">
                        <v-card class="elevation-0 font-weight-bold primary--text grey lighten-5 d-flex align-center" height="100%">
                            <div class="text-center pa-2 mx-auto text-h6 font-weight-bold">
                                <v-chip class="ma-2">{{idx+1}}</v-chip> <br>
                                {{Action.Treatment}} 
                                <!-- <br> -->
                                <!-- <v-btn color="grey" icon small class="mt-2 ml-2">
                                  <v-icon>mdi-pencil</v-icon>
                                </v-btn> -->
                            </div>
                        </v-card>
                    </div>
                    <div class="col ma-3 grey lighten-3">
                    <v-data-table
                        :headers="headersExpanded.filter(a=>{ return a.text !== 'Treatment / Action'})"
                        :items="[...Action]"
                        item-key=".key"
                        hide-default-footer
                        :loading="loading"
                    >
                        <template v-slot:item.Timeline="props">
                            <v-edit-dialog
                            :return-value.sync="props.item.Timeline"
                            @save="save(props.item)"
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                            large
                            persistent
                            >
                            {{ props.item.Timeline }}
                            <template v-slot:input>
                                <v-textarea
                                v-model="props.item.Timeline"
                                class="mt-4"
                                outlined
                                label="Timeline"
                                auto-grow
                                autofocus
                                ></v-textarea>
                            </template>
                            </v-edit-dialog>
                        </template>       
                        <template v-slot:item.InspectionMethod="props">
                            <v-edit-dialog
                            :return-value.sync="props.item.InspectionMethod"
                            @save="save(props.item)"
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                            large
                            persistent
                            >
                            {{ props.item.InspectionMethod }}
                            <template v-slot:input>
                                <v-textarea
                                v-model="props.item.InspectionMethod"
                                class="mt-4"
                                outlined
                                label="Inspection Method"
                                auto-grow
                                autofocus
                                ></v-textarea>
                            </template>
                            </v-edit-dialog>
                        </template>  
                        <template v-slot:item.Frequency="props">
                            <v-edit-dialog
                            :return-value.sync="props.item.Frequency"
                            @save="save(props.item)"
                            @cancel="cancel(props.item)"
                            @open="open(props.item)"
                            @close="close(props.item)"
                            large
                            persistent
                            >
                            {{ props.item.Frequency }}
                            <template v-slot:input>
                                <v-textarea
                                v-model="props.item.Frequency"
                                class="mt-4"
                                outlined
                                label="Frequency"
                                auto-grow
                                autofocus
                                ></v-textarea>
                            </template>
                            </v-edit-dialog>
                        </template>   
                        <template v-slot:item.Effective="props">
                            <v-edit-dialog
                            :return-value.sync="props.item.Effective"
                            @save="save(props.item)"
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                            large
                            persistent
                            >
                            <v-chip :color="props.item.Effective ? 'success' : ''" small>
                                <v-icon  v-if="props.item.Effective" small>
                                    mdi-check-circle
                                </v-icon>
                                <v-icon  v-else small>
                                    mdi-close-circle
                                </v-icon>  
                            </v-chip>
                            <template v-slot:input>
                             <v-checkbox autofocus label="Effective" v-model="props.item.Effective"></v-checkbox>
                            </template>
                        </v-edit-dialog>
                        </template>
                        <template v-slot:item.Implemented="props">
                            <v-edit-dialog
                            :return-value.sync="props.item.Implemented"
                            @save="save(props.item)"
                            @cancel="cancel"
                            @open="open"
                            @close="close"
                            large
                            persistent
                            >
                            <v-chip :color="props.item.Implemented ? 'success' : ''" small>
                                <v-icon  v-if="props.item.Implemented" small>
                                    mdi-check-circle
                                </v-icon>
                                <v-icon  v-else small>
                                    mdi-close-circle
                                </v-icon>                                
                            </v-chip>
                            <template v-slot:input>
                             <v-checkbox autofocus label="Implemented" v-model="props.item.Implemented"></v-checkbox>
                            </template>
                        </v-edit-dialog>
                        </template>
 
                    </v-data-table>
                    <v-data-table
                        :headers="headersExpanded1"
                        :items="[...Action]"
                        item-key=".key"
                        hide-default-footer
                        :loading="loading"
                    >
                        <template v-slot:top>
                            <div
                                flat
                                class=" text-center grey lighten-2 grey--text my-4 font-weight-bold pa-2"
                            >
                                IMPACT OF THE RISK THAT TRANSPIRED						
                            </div>
                        </template>

                        <template v-slot:item.ImpactNIAT="props">
                            <v-edit-dialog
                            :return-value.sync="props.item.ImpactNIAT"
                            @save="save(props.item)"
                            @cancel="cancel(props.item)"
                            @open="open(props.item)"
                            @close="close(props.item)"
                            large
                            persistent
                            >
                            {{ props.item.ImpactNIAT }}
                            <template v-slot:input>
                                <v-slider
                                    class="mt-16"
                                    v-model="props.item.ImpactNIAT"
                                    color="accent"
                                    min="0"
                                    max="5"
                                    thumb-label
                                ></v-slider>
                            </template>
                            </v-edit-dialog>
                        </template>   

                        <template v-slot:item.ImpactReputation="props">
                            <v-edit-dialog
                            :return-value.sync="props.item.ImpactReputation"
                            @save="save(props.item)"
                            @cancel="cancel(props.item)"
                            @open="open(props.item)"
                            @close="close(props.item)"
                            large
                            persistent
                            >
                            {{ props.item.ImpactReputation }}
                            <template v-slot:input>
                                <v-slider
                                    class="mt-16"
                                    v-model="props.item.ImpactReputation"
                                    color="accent"
                                    min="0"
                                    max="5"
                                    thumb-label
                                ></v-slider>
                            </template>
                            </v-edit-dialog>
                        </template> 

                        <template v-slot:item.ImpactLegal="props">
                            <v-edit-dialog
                            :return-value.sync="props.item.ImpactLegal"
                            @save="save(props.item)"
                            @cancel="cancel(props.item)"
                            @open="open(props.item)"
                            @close="close(props.item)"
                            large
                            persistent
                            >
                            {{ props.item.ImpactLegal }}
                            <template v-slot:input>
                                <v-slider
                                    class="mt-16"
                                    v-model="props.item.ImpactLegal"
                                    color="accent"
                                    min="0"
                                    max="5"
                                    thumb-label
                                ></v-slider>
                            </template>
                            </v-edit-dialog>
                        </template> 


                        <template v-slot:item.ImpactEnvironment="props">
                            <v-edit-dialog
                            :return-value.sync="props.item.ImpactEnvironment"
                            @save="save(props.item)"
                            @cancel="cancel(props.item)"
                            @open="open(props.item)"
                            @close="close(props.item)"
                            large
                            persistent
                            >
                            {{ props.item.ImpactEnvironment }}
                            <template v-slot:input>
                                <v-slider
                                    class="mt-16"
                                    v-model="props.item.ImpactEnvironment"
                                    color="accent"
                                    min="0"
                                    max="5"
                                    thumb-label
                                ></v-slider>
                            </template>
                            </v-edit-dialog>
                        </template> 

                        <template v-slot:item.ImpactHealth="props">
                            <v-edit-dialog
                            :return-value.sync="props.item.ImpactHealth"
                            @save="save(props.item)"
                            @cancel="cancel(props.item)"
                            @open="open(props.item)"
                            @close="close(props.item)"
                            large
                            persistent
                            >
                            {{ props.item.ImpactHealth }}
                            <template v-slot:input>
                                <v-slider
                                    class="mt-16"
                                    v-model="props.item.ImpactHealth"
                                    color="accent"
                                    min="0"
                                    max="5"
                                    thumb-label
                                ></v-slider>
                            </template>
                            </v-edit-dialog>
                        </template> 

                        <template v-slot:item.ImpactOperational="props">
                            <v-edit-dialog
                            :return-value.sync="props.item.ImpactOperational"
                            @save="save(props.item)"
                            @cancel="cancel(props.item)"
                            @open="open(props.item)"
                            @close="close(props.item)"
                            large
                            persistent
                            >
                            {{ props.item.ImpactOperational }}
                            <template v-slot:input>
                                <v-slider
                                    class="mt-16"
                                    v-model="props.item.ImpactOperational"
                                    color="accent"
                                    min="0"
                                    max="5"
                                    thumb-label
                                ></v-slider>
                            </template>
                            </v-edit-dialog>
                        </template> 

                        <template v-slot:item.HighestImpact="props">
                            {{getHighest(props.item)}}
                        </template>

                    </v-data-table>


                    <v-data-table
                        :headers="headersExpanded2"
                        :items="[...Action]"
                        item-key=".key"
                        class="mt-4"
                        hide-default-footer
                        :loading="loading"
                    >
                        <template v-slot:item.Last6Months="props">
                            <v-edit-dialog
                            :return-value.sync="props.item.Last6Months"
                            @save="save(props.item)"
                            @cancel="cancel(props.item)"
                            @open="open(props.item)"
                            @close="close(props.item)"
                            large
                            persistent
                            >
                            {{ props.item.Last6Months }}
                            <template v-slot:input>
                                <v-textarea
                                v-model="props.item.Last6Months"
                                class="mt-4"
                                outlined
                                label="Last 6 Months ave. REVENUE"
                                auto-grow
                                autofocus
                                ></v-textarea>
                            </template>
                            </v-edit-dialog>
                        </template>   
                        <template v-slot:item.ThresholdAmount="props">
                            <v-edit-dialog
                            :return-value.sync="props.item.ThresholdAmount"
                            @save="save(props.item)"
                            @cancel="cancel(props.item)"
                            @open="open(props.item)"
                            @close="close(props.item)"
                            large
                            persistent
                            >
                            {{ props.item.ThresholdAmount }}
                            <template v-slot:input>
                                <v-textarea
                                v-model="props.item.ThresholdAmount"
                                class="mt-4"
                                outlined
                                label="Threshold Amount"
                                auto-grow
                                autofocus
                                ></v-textarea>
                            </template>
                            </v-edit-dialog>
                        </template>   
                    </v-data-table>



                    </div>

                    </v-row>

                </td>              
            </template>
        </v-data-table>
    </v-card>
    <v-navigation-drawer
        absolute
        right
        temporary
        width="350"
        v-model="historyDrawer"
    >
    <div class="text-h6 pa-4 grey--text text--darken-2">
        <v-icon class="mr-2">mdi-history</v-icon>
        Report Coverage
    </div>
    <div class="px-4">
        <v-text-field
            label="Search Coverage"
            outlined
            dense
            v-model="searchCoverage"
        ></v-text-field>
    </div>
        <v-timeline
            dense
            clipped
            class="pr-8"
        >
            <v-timeline-item
                v-for="event in filteredCoverage"
                :key="event['.key']"
                class="mb-4"
                :class="coverageFilterKey == event['.key'] ? 'primary lighten-2' : ''"
                style="cursor:pointer;"
                :color="coverageFilterKey == event['.key'] ? 'primary' : 'grey'"
                small
                @click.native="coverageFilterKey = event['.key'],coverageDate = event.startDate"
            >
                <v-row justify="space-between">
                    <v-col
                    cols="7"
                    v-text="$moment(event.startDate).format('MMMM YYYY')"
                    ></v-col>
                    <v-col
                    class="text-right text-caption"
                    cols="5"
                    >
                        {{$moment(event.CreatedAt.toDate()).format('MM/DD/YYYY HH:mm A')}}
                    </v-col>
                </v-row>
            </v-timeline-item>

        </v-timeline>
    </v-navigation-drawer>
    </v-container>
</template>
<script>
export default {
    data: () => ({
        historyDrawer: false,
        NAVitems: [
          { title: 'Home', icon: 'mdi-view-dashboard' },
          { title: 'About', icon: 'mdi-forum' },
        ],
        deptFilter: 'SYSDEV',
        search: '',
        headers: [
            { text: 'Risk Ranking', sortable: true, value: 'Rank', align: 'center' },
            { text: 'Risk Name', sortable: true, value: 'RiskName' },
            { text: 'Strategy', sortable: true, value: 'Strategy' },
            { text: 'Risk Rating (Vulnerability Score)', sortable: true, value: 'VulnerabilityScore',align: 'center' },
            { text: '', value: 'data-table-expand' },
        ],
        headersExpanded: [
            { text: 'Treatment / Action', sortable: true, value: 'Treatment' },
            { text: 'Timeline', sortable: true, value: 'Timeline' },
            { text: 'Inspection Method', sortable: true, value: 'InspectionMethod' },
            { text: 'Frequency', sortable: true, value: 'Frequency' },
            // { text: 'Results', sortable: true, value: 'Results' },
            { text: 'Effective [RESULT]', sortable: true, value: 'Effective' },
            { text: 'Implemented [RESULT]', sortable: true, value: 'Implemented' },
            // { text: '', value: 'data-table-expand' },
        ],
        headersExpanded1: [
            { text: 'NIAT', sortable: true, value: 'ImpactNIAT' },
            { text: 'Reputation', sortable: true, value: 'ImpactReputation' },
            { text: 'Legal & Compliance', sortable: true, value: 'ImpactLegal' },
            { text: 'Environment', sortable: true, value: 'ImpactEnvironment' },
            // { text: 'Results', sortable: true, value: 'Results' },
            { text: 'Health & Safety', sortable: true, value: 'ImpactHealth' },
            { text: 'Operational', sortable: true, value: 'ImpactOperational' },
            { text: 'Highest Impact', sortable: true, value: 'HighestImpact' },
            // { text: '', value: 'data-table-expand' },
        ],
        headersExpanded2: [
            { text: 'Last 6 Months ave. REVENUE(If Applicable)', sortable: true, value: 'Last6Months' },
            { text: 'Threshold Amount', sortable: true, value: 'ThresholdAmount' },
        ],
        items: [],
        expanded: [],
        updated: [],
        singleExpand: true,
        itemOriginalValue: null,
        loading: false,  
        coverageFilterKey: null,
        coverageDate: null,
        searchCoverage: '',
        showBanner: false,  
    }),
    props: ['query'],
    watch: {
        deptFilter: function (val) {
            if(this.$store.getters['useraccount/isAuthenticated'].department == 'RISK MANAGEMENT TEAM'){
                this.$store.commit('logs/setDataOwner', val)
                console.log(this.$store.getters['logs/getDataOwner'],'data owner in watch');  
            }
        },          
    },

    created(){
        if(this.$store.getters['useraccount/isAuthenticated'].department == 'RISK MANAGEMENT TEAM'){
            this.$store.commit('logs/setDataOwner', this.deptFilter)
        } 
        console.log(this.$store.getters['logs/getDataOwner'],'data owner');           
        if(this.query){
            console.log(this.query,'search')
        }
    },

    firestore(){
      return {
        UserAccounts: this.$db.collection('UserAccounts'),
        Departments: this.$db.collection('Departments'),
        ORMP: this.$db.collection('ORMP').orderBy('Vulnerability','desc'),
        // ORMPCoverage: this.$db.collection('ORMPCoverage').orderBy('startDate','desc'),
        ORMPLevels: this.$db.collection('ORMPLevels').orderBy('CreatedAt','desc'),
        RAEME_REPORTS: this.$db.collection('RAEME_REPORTS'),
        ORMPCoverage: 
        {
        // collection reference.
            ref: this.$db.collection('ORMPCoverage').orderBy('startDate','desc'),
            // Bind the collection as an object if you would like to.
            resolve: (data) => {
                // collection is resolved
                console.log(data,'ORMPCoverage')
                let self = this
                if(self.query){
                    let arr = self.query.split('/')
                    let search = data.filter(a=>{
                        return self.$moment(a.startDate).format('MMMM YYYY') == arr[0] && a.Department == arr[1]
                    })

                    if(search.length == 0){
                        self.showBanner = true
                        self.deptFilter = arr[1]
                        console.log(self.deptFilter,'query');
                    } else {
                        self.coverageFilterKey = search[0]['.key']
                        self.coverageDate = search[0].startDate
                        self.deptFilter = arr[1]
                        console.log('here code runs');
                        console.log(self.deptFilter,self.coverageDate,self.coverageFilterKey);
                    }

                } else {
                    console.log('running else');
                    let first = data.filter(a=>{
                        return a.isApproved == true && a.Department == self.deptFilter
                    })
                    self.coverageFilterKey = first[0]['.key']
                    self.coverageDate = first[0].startDate
                }
            },
            reject: (err) => {
                console.log('err-ORMPCoverage',err)
                // collection is rejected
            }
        },
      }
    },   
    computed:{
        returnORMPFilter(){
            if(this.$store.getters['useraccount/isAuthenticated'].department !== 'RISK MANAGEMENT TEAM') {
                return false
            }
            return true
        },
        returnORMP(){
            if(!this.ORMP) return []
            if(this.ORMP.length == 0) return []
            let self = this

            let ormp = this.ORMPLevels.filter(a=>{
                return a.CoverageId == this.coverageFilterKey && a.RiskManagementLevel == 'Residual'
            })

            console.log(ormp,'ormp filtered');

            let before = this.$lodash.map(ormp,function(a,index){
                let otherData = {...a}
                let getData = self.getORMPData(a.ORMPId,a.CoverageId)
                return {
                    Rank: index+1,
                    RiskName: getData.RiskName.RiskName,
                    Department: getData.Deparment,
                    Strategy: a.RiskStrategy.RiskStrategy,
                    VulnerabilityScore: a.Vulnerability,
                    // Reports: self.returnRAEMEREPORTS(a['.key']).length > 0 ? self.returnRAEMEREPORTS(a['.key']) : null,
                    Reports: self.returnRAEMEREPORTSUpdate(a),
                    ...otherData       
                }
            })

            // console.log(before,'before');

            let user = self.$store.getters['useraccount/isAuthenticated']
            
            if(user.department !== 'RISK MANAGEMENT TEAM'){

                return before.filter(a=>{
                    return a.Department == user.department
                })          
            }

            if(self.deptFilter !== 'ALL DEPARTMENTS'){

                return before.filter(a=>{
                    return a.Department == self.deptFilter
                })
            }

            console.log(before,'before')

            return before

        },
        returnDept(){
            let db = this.Departments.map(a=>{
                return {
                    text: a.departmentName,
                    value: a.departmentName
                }
            })
            return db.filter(a=>{return a.text !== 'RISK MANAGEMENT TEAM'})
        },
        returnHistory(){
            // coverage
            let self = this

            let before = this.ORMPCoverage.filter(a=>{
                return a.isApproved == true
            })
        
            let user = self.$store.getters['useraccount/isAuthenticated']
            
            if(user.department !== 'RISK MANAGEMENT TEAM'){
                console.log(before.filter(a=>{
                    return a.Department == user.department
                }));
                return before.filter(a=>{
                    return a.Department == user.department
                })          
            }

            if(self.deptFilter !== 'ALL DEPARTMENTS'){
                console.log(before.filter(a=>{
                    return a.Department == self.deptFilter
                }),'NOT ALL');
                return before.filter(a=>{
                    return a.Department == self.deptFilter
                })
            }

            return before
        },
        filteredCoverage() {
            return this.returnHistory.filter(item => {
                let search = `${this.$moment(item.startDate).format('MMMM YYYY')}`
                return search.toLowerCase().indexOf(this.searchCoverage.toLowerCase()) > -1
            })
        },
    },
    methods:{
        getHighest(item){
            let array = [
                item.ImpactReputation,
                item.ImpactLegal,
                item.ImpactEnvironment,
                item.ImpactNIAT,
                item.ImpactHealth,
                item.ImpactOperational
            ]

            let filter = array.filter(a=>{
                return a !== undefined || a !== ''
            })
            
            return this.$lodash.max(filter)
        },
        getORMPData(ormpid,coverageid){
            return this.ORMP.filter(a=>{
                return a['.key'] == ormpid && a.CoverageId == coverageid
            })[0]
        },
        generateRAEME(itemProps){
            let user = this.$store.getters['useraccount/isAuthenticated']
 
            let RAEME = {
                ...itemProps,
                timestamp: this.$firebase.firestore.FieldValue.serverTimestamp(),
                userID: user.email,
            }     

            this.$db.collection('RAEME_REPORTS').add(RAEME)
            .then(async (doc)=>{
                let id = doc.id
                await this.$store.dispatch('logs/createNotifs', {collection:'RAEME_REPORTS',documentKey:id,module: 'RAEME',action: 'Added New RAEME REPORT'})  
                this.$dialog.notify.success(`Success Creating RAEME Report`, {
                    position: 'bottom-right',
                    timeout: 3000
                })    
            }).catch(err=>{
                this.$dialog.notify.success(`Error : ${err}`, {
                    position: 'bottom-right',
                    timeout: 3000
                })              
            })                    


        },
        returnRAEMEREPORTS(key){
            if(this.loading) return []
            if(this.RAEME_REPORTS.length == 0) return []
            return this.RAEME_REPORTS.filter(a=>{
                return a.riskKey == key
            })
        },
        returnRAEMEREPORTSUpdate(item){
            let ormp = {...item}
            let reports = []


            let checkReports = this.$lodash.filter(this.RAEME_REPORTS,a=>{
                return a.riskKey == ormp['.key'] && a.coverageKey == ormp.CoverageId 
            })

            // console.log(checkReports,'checkReports');


            ormp.RiskActions.forEach(a=>{
                if(this.$lodash.findIndex(checkReports, x=>{ return a.text == x.Treatment }) == -1) {

                    reports.push({
                        Treatment: a.text,
                        Timeline: '-',
                        InspectionMethod: '-',
                        Frequency: '-',
                        Implemented: false,
                        Effective:false,
                        timestamp: '', //this.$firebase.firestore.FieldValue.serverTimestamp()
                        userID: '', //this.$firebase.firestore.FieldValue.serverTimestamp()
                        riskKey: ormp['.key'],
                        coverageKey: ormp.CoverageId,
                        ImpactNIAT: '',
                        ImpactReputation: '',
                        ImpactLegal: '',
                        ImpactEnvironment: '',
                        ImpactHealth: '',
                        ImpactOperational: '',
                        Last6Months: '',
                        ThresholdAmount: '',  
                        Department: ormp.Department   
                    })

                } 
            })


            let concat = [...reports,...checkReports]

            // console.log(this.$lodash.uniqBy(concat,'Treatment'),'concat');

            return this.$lodash.uniqBy(concat,'Treatment')
        },
        save(props){
            console.log(props,'pprops');
            let report = {...props}
            let key = props['.key']

            console.log(key,'key');

            if(key !== undefined){
                console.log('SET');
                let user = this.$store.getters['useraccount/isAuthenticated']
                delete report['.key']

                report.modifiedOn = this.$firebase.firestore.FieldValue.serverTimestamp()
                report.lastModifiedBy = user.email

                this.$db.collection('RAEME_REPORTS').doc(key).set(report)
                .then(async()=>{
                    await this.$store.dispatch('logs/createNotifs', {collection:'RAEME_REPORTS',documentKey:key,module: 'RAEME',action: 'Updated a RAEME REPORT'})  
                    this.$dialog.notify.success(`Success Updated RAEME Report`, {
                        position: 'bottom-right',
                        timeout: 3000
                    })    
                }).catch(err=>{
                    this.$dialog.notify.success(`Error : ${err}`, {
                        position: 'bottom-right',
                        timeout: 3000
                    })              
                }) 
            } else {
                console.log('ADD');
                this.generateRAEME(report)
            }

            
           
        },  
        close(){},
        cancel(){},
        open(){}
    } 
}
</script>